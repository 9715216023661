import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArchivoConcesionarioDTO, ArchivoDTO } from '@app/models/archivo.model';
import { ArchivosService } from '@app/services/archivos/archivos.service';
import { FileReaderService } from '../home/home.component';
import { ReclamoService } from '@app/services/reclamos/reclamo.service';
import { ReclamoResponseDTO } from '@app/models/response.model';
import { Subscription } from 'rxjs';
import { TokenService } from '@app/services/auth/token.service';
import { User, UserModel, detalleEX } from '@app/models/user.model';
import { AuthService } from '@app/services/auth/auth.service';

@Component({
  selector: 'app-home-concesionario',
  templateUrl: './home-concesionario.component.html',
  styleUrls: ['./home-concesionario.component.css']
})
export class HomeConcesionarioComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();
  user: User;
  concesionario= '';

  filename= 'adjuntar'

  formData: FormData = new FormData();
  public reclamos:ReclamoResponseDTO[];
  public archivos: ArchivoConcesionarioDTO[]=[];
  public archivosNuevos= false;
  public caracteres= 0;
  
  public ModalActivate: boolean = false;
  public ModalResponseActivate: boolean = false;
  public ModalConfirmacion: boolean= false;
  ModalNombreActivate: boolean= false;

  public loading: boolean = true;
  public isShowForm: boolean = false;
  public isFormActive: boolean = false;
  public isDetallesEndososActive: boolean = false;
  public errorMsgActive: boolean = false;
  public errorMsg: String = '';

  public archivoEliminar!:ArchivoDTO;
  
  public formArchivo: FormGroup;

  // OTROS
  public switchOpcion: any;
  public gestionesMensaje: string;
  respEstado: string;
  respMensaje: any;
  detalle: detalleEX;
  ModalNombreMensaje: string;

 

  constructor(
    public fbuilder: FormBuilder,
    private archivoService: ArchivosService,
    private fileReaderService: FileReaderService,
    private tokenService: TokenService,
    private authService: AuthService
    ) {

      this.formArchivo = this.fbuilder.group({
          ctrlNombre: ['', Validators.required],
          ctrlArchivo: [{},Validators.required],
          ctrlComentario:['', Validators.maxLength(500)],
      });

  }


  ngOnInit() {      
    setTimeout(() => {
        this.user= this.authService.currentUser;
        this.detalle = new detalleEX(this.user.detalle);
        this.filtrarArchivos();
      },1000);
  }

  activarModal(){
    
    this.ModalActivate=!this.ModalActivate;
    this.resetForm();
  }

  activarModalResponse(){
    
    this.ModalResponseActivate=!this.ModalResponseActivate;
  }

  activarModalNombre(data: string){
    
    this.ModalNombreActivate= true;
    this.ModalNombreMensaje=data;
  }

  // FUNCION PARA MANEJO DE ARCHIVOS
  public async onFileSelected(event: any): Promise<void> {
    this.errorMsgActive= false;
    const file: File = event.target.files[0];

    if(file){
      const size: number = file.size / 1024;

      if(file.type != "application/pdf" 
          && file.type != 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
          && file.type != 'application/vnd.ms-excel' && file.type != 'application/msword'){

        this.errorMsgActive= true;
        this.errorMsg= 'Solo se aceptan archivos docx, xlsx o pdf'

        return;
      }
      if( size > 50000 ){
    
        this.errorMsgActive= true;
        this.errorMsg= 'El tamaño del archivo no puede ser mayor a 50mb'
    
        return;
      }

      this.filename= file.name;

        const base64 = await this.fileReaderService.readAsDataURL(file);
        const archivo = {
            tipoArchivo: file.type,
            volumenArchivo: (file.size / 1024).toFixed(),
            nombreArchivo: file.name,
            base64: base64
        };
        this.formArchivo.get('ctrlArchivo').setValue(archivo);
    }

  }

  filtrarArchivos(){
    const data = {
      "id": null,
      "nombre": null,
      "nombreArchivo": null,
      "estado": null,
      "userCrea": null,
      "concesionario": this.detalle.id,
      "fechaCreacion": null,
      "fechaModificado": null,
      "fechaEliminado": null,
      "page": null,
      "size": null
    }
    this.subscriptions.add(this.archivoService.filtrarArchivoConcesionario(data).subscribe( (resp)=>{
      this.archivos= resp.data
      if(this.archivos.length > 0){
        this.archivosNuevos= true;
      }
      
    }))
  }
    

  enviarArchivo(){

    const data= {
      nombre: this.formArchivo.get('ctrlNombre').value,
      archivo: this.formArchivo.get('ctrlArchivo').value,
      comentario: this.formArchivo.get('ctrlComentario').value,
      tipo: 'PRI',
      userCrea: this.tokenService.id
    }
    this.subscriptions.add(this.archivoService.enviarArchivo(data).subscribe(
      (resp) => {
        if(resp.codigoRespuesta == '0'){
          this.ModalActivate= false;
          this.ModalResponseActivate = true;
          this.respEstado= 'Solicitud Exitosa'
          this.respMensaje= resp.mensaje;
          
          this.resetForm();
          this.filtrarArchivos();
        }else{
          this.ModalResponseActivate = true;
          this.respEstado= 'Error en la solicitud'
          this.respMensaje= resp.mensaje;
        }
      }
    ))
  }

  resetForm(){
    this.formArchivo.reset();
  }

  eliminarArchivo(archivo: ArchivoDTO){
    this.archivoEliminar= archivo;
    this.ModalConfirmacion= true;
  }

  action(confirmacion: boolean){
    if(confirmacion){
      this.subscriptions.add(this.archivoService.eliminarArchivo(btoa(this.archivoEliminar.id)).subscribe(
        (resp) => {
          if(resp.codigoRespuesta == '0'){
            this.ModalResponseActivate = true;
            this.respEstado= 'Solicitud Exitosa'
            this.respMensaje= resp.mensaje;
            this.resetForm();
          }else{
            this.ModalResponseActivate = true;
            this.respEstado= 'Error en la solicitud'
            this.respMensaje= resp.mensaje;
          }
          this.ModalConfirmacion = false;
        }
        ))
    }else{
      this.ModalConfirmacion = false;
    }
  }

  ngOnDestroy(){
    this.subscriptions.unsubscribe
  }
  
  public getDownloadUrl(archivo: any) {

    this.archivoService.getDownloadUrlConcesionario( btoa(archivo.id)).subscribe((response: any) => {
      if (response.status === "OK" && response.codigoRespuesta === "0") {
        const dataFile = response.data.urlDescarga;
        this.downloadFile(dataFile, archivo);
      } else {
        console.warn(response.mensaje);
      }
    });

  }

  public downloadFile(dataFile: string, archivo: any) {
    const blob = this.base64ToBlob(dataFile, archivo.nombreArchivo);
    const url = window.URL.createObjectURL(blob);
  
    const a = document.createElement('a');
    a.href = url;
    a.download = archivo.nombre;
    a.style.display = 'none';
    
    document.body.appendChild(a);
    a.click();
    
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  private base64ToBlob(base64: string, contentType: string) {

    const fullFileName = contentType;
    const lastDotIndex = fullFileName.lastIndexOf('.');
    const nameWithoutExtension = fullFileName.substring(0, lastDotIndex);
    const fileType = fullFileName.substring(lastDotIndex + 1);

    // Construir el nuevo nombre del archivo sin timestamp
    const newFileName = `${nameWithoutExtension}.${fileType}`;
    //console.log(newFileName);

    //const fileType = contentType.split('.')[1];

    if(fileType === 'docx'){
      contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ;
    }
    if(fileType === 'pdf'){
      contentType = 'application/pdf' ;
    }
    if(fileType === 'xlsx'){
      contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ;
    }
    if(fileType === 'xls'){
      contentType = 'application/vnd.ms-excel' ;
    }
    if(fileType === 'doc'){
      contentType = 'application/msword' ;
    }

    
    const sliceSize = 512;
    const byteCharacters = atob(base64);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  contadorCaractes(){

    let comentario =this.formArchivo.get('ctrlComentario').value

    this.caracteres= comentario.length;
  }
}


