import { trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/services/auth/auth.service';
import { TokenService } from '@app/services/auth/token.service';
import { UserService } from '@app/services/user/user.service';

@Component({
  selector: 'app-resetPassword',
  templateUrl: './resetPassword.component.html',
  styleUrls: ['./resetPassword.component.css']
})
export class ResetPasswordComponent implements OnInit {


  public token = this._route.snapshot.url[3].path;
  public id = this._route.snapshot.url[2].path;
  public mensaje = '';
  ModalResponseActivate= false;
  respEstado='';
  respMensaje='';

  public valido= false;

  public recoverPass: FormGroup = this.fb.group({
    nuevaClave: ['', [Validators.required, Validators.minLength(8)]],
    repeticionClave: ['', [Validators.required, Validators.minLength(8)]]
  });

  constructor(
    private _route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private tokenService: TokenService,
    private userService: UserService
  ) {


  }

  ngOnInit() {
    this.validarToken();
  }
  resetForm() {
    this.recoverPass.reset();
  }

  validarToken() {
    
    this.userService.validarToken(this.token).subscribe((resp) => {
      if (resp.codigoRespuesta != 0) {
          this.mensaje = resp.mensaje
      }
      if(resp.codigoRespuesta == 0) {
        this.valido= true;
      }
    }, (err) => {
      this.mensaje = 'Su token ha expirado'
    });
  }

  cambiarPass() {
    this.mensaje = '';
    const datos = {
      userId: this.id,
      password: this.recoverPass.get('nuevaClave').value,
      passwordRepetida: this.recoverPass.get('repeticionClave').value,
    } 

    if (this.recoverPass.valid) {
      
      if(datos.password != datos.passwordRepetida){
        this.mensaje = 'Las claves deben coincidir';
        return;
      }

      this.userService.changePassword(datos, this.token).subscribe(
        (resp) => {
          if (resp.codigoRespuesta != 0) {
            this.ModalResponseActivate= true;
            this.respEstado = 'Error en la solicitud'
            this.respMensaje= resp.mensaje
        }
        if(resp.codigoRespuesta == 0) {
          this.ModalResponseActivate= true;
          this.respEstado = 'Solicitud Exitosa'
          this.respMensaje= resp.mensaje
        }
        },
        (err) => {
        }
      );
    }
  }

  activarModalResponse(){
    this.ModalResponseActivate= false;
    this.tokenService.logOut();
  }
}
