
<!-- INICIO LOGIN -->
<section class="container mb-5 mt-2 pb-2 pt-1">
    <div class="content py-5 px-3 my-3 mx-4">
        <div class="row-login flex-row text-center">
            <div class="col-12">
                <h2 class="bold mb-3">acceso al gestor de archivos</h2>
            </div>
            <!-- FORM PARA INICIO SESION -->
            <!--<div class="col-6 border-1 border-dark border-end">-->
                <div class="form-content-login py-3 px-1-5 mx-3">
                    <div *ngIf="isShowError" class="form-group my-1">

                        <div class="alert alert-danger alert-dismissible show text-start" role="alert">
                            <svg class="icon" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id=" Navigation / Error">
                                <path fill="#C3261F" id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M12.3669 4L12.7997 4.74741L20.1666 17.4721L20.6011 18.2226H19.7339H5.00004H4.13281L4.56732 17.4721L11.9342 4.74741L12.3669 4ZM5.86726 17.2226H18.8666L12.3669 5.99586L5.86726 17.2226ZM11.8 13.7H12.89L13.0202 8.7H11.6802L11.8 13.7ZM11.6802 16.0135H13.0196V14.674H11.6802V16.0135Z"/>
                                </g>
                            </svg>
                            <strong>{{errorMsg}}</strong>
                            
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="closeModal()">
                            </button>
                        </div>

                    </div>
                    <!-- <div *ngIf="isShowAlert" class="form-group my-1">

                        <div class="alert alert-danger alert-dismissible show text-start" role="alert">
                            <svg class="icon" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id=" Navigation / Error">
                                <path fill="#C3261F" id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M12.3669 4L12.7997 4.74741L20.1666 17.4721L20.6011 18.2226H19.7339H5.00004H4.13281L4.56732 17.4721L11.9342 4.74741L12.3669 4ZM5.86726 17.2226H18.8666L12.3669 5.99586L5.86726 17.2226ZM11.8 13.7H12.89L13.0202 8.7H11.6802L11.8 13.7ZM11.6802 16.0135H13.0196V14.674H11.6802V16.0135Z"/>
                                </g>
                            </svg>
                            <strong>Usuario o contraseña inválido</strong>
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                            </button>
                        </div>

                    </div> -->
                    <h6 class="mb-2">Ingresá con tu cuenta</h6>
                    <form [formGroup]="usuarioLogin" (ngSubmit)="onSubmit(usuarioLogin.value)">
                        <div class="form-row">
                            <div class="form-group mb-1-5">
                                <div class="form-floating">
                                    <input formControlName="email" name="user" type="text" class="form-control" placeholder="name@example.com" autocomplete="off">
                                    <label *ngIf="!usuarioLogin.get('email').errors?.touched">Usuario *</label>                                
                                    <!-- VALIDACION -->
                                    <div *ngIf="usuarioLogin.get('email').touched && usuarioLogin.get('email').errors?.required" class="text-start" >
                                        <p class="color-error"> El campo es requerido.</p>
                                    </div>
                                    <div *ngIf="usuarioLogin.get('email').errors?.['pattern']?.requiredPattern && this.usuarioLogin.get('email').invalid" class="text-start" >
                                        <p class="color-error">Debe ingrear un correo válido</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-1-5">
                                <div class="form-floating">
                                    <input formControlName="password" name="pass" type="password" class="form-control" placeholder="Password" autocomplete="off">
                                    <label>Contraseña *</label>

                                    <!-- VALIDACION -->
                                    <div *ngIf="usuarioLogin.get('password').touched && usuarioLogin.get('password').errors?.required" class="text-start">
                                        <p class="color-error">El campo es requerido.</p>
                                    </div>


                                </div>
                            </div>
                            <div class="form-group m-0-5">
                                <button type="submit" class="btn btn-primary btn-default" [disabled]="usuarioLogin.invalid"> ingresar </button>
                            </div>
                            <!-- NAV A RESTABLECER CONTRA -->
                            <div class="text-center mt-2 mb-0-5">
                                ¿Olvidaste tu <a class="color-negro link-nav" style="cursor: pointer;" (click)="ModalContrasena= true">contraseña</a>?
                            </div>
                            
                        </div>
                    </form>
                </div>
            <!--</div>-->
            <!-- ------------------------ -->
            <!-- NAVEG A REGISTRO 
            <div class="col-6">
                <div class="py-3 px-1-5 mx-3">
                    <i class="icon-account icon-lg"></i>
                    <p class="body-text-l">
                        Si aún no te registraste,
                        <button class="border-0 bg-blanco link-nav" >activa</button>
                         <a  class="color-negro link-nav" [routerLink]="['registro']">activa</a>  
                        tu cuenta
                    </p>
                </div>

            </div>
             ------------------------ -->
        </div>
    </div>
</section>
<!-- FINAL LOGIN -->



<div *ngIf="ModalContrasena">
    <div class="modal-contrasena" >
      <div class="cookies-personalizar-content">
        <div class="cookies-personalizar-header">
          <div class="titulo"><h4 class="bold">Recuperar contraseña</h4></div>
          <button class="btn-cerrar h-2 bold" (click)="ModalContrasena= false">X</button>
        </div>
        <div class="body">
            <div class="modal-form form-content mx-3">
                    <div class="form-row">
                        <div class="form-group mb-0-5 ">
                            <p>Ingrese su correo</p>
                            <div class="form-floating">
                                <input [(ngModel)]="email" name="nombre" type="text" class="form-control" placeholder="name@example.com" autocomplete="off">
                                <label>Mail </label>
                            </div>
                        </div>
                        
                        <div class="form-group m-0-5">
                            <button type="submit" class="btn btn-primary btn-default" (click)="enviarMailActivacion()">  enviar </button>
                        </div>
                    </div>
            </div>
        </div>
      </div>
    </div>
</div>

<div *ngIf="ModalResponseActivate">
    <div class="modal-response" >
        <div class="cookies-personalizar-content">
            <div class="cookies-personalizar-header">
                <div class="titulo">
                    <h5 class="bold"></h5>
                </div>
                <button class="btn-cerrar h-2 bold" (click)="ModalResponseActivate= false">X</button>
            </div>
            <div class="body">
                <div class="modal-form form-content mx-3 mb-2">
                    <h6>{{respEstado}}</h6>
                </div>
                <div class="modal-form form-content mx-3">

                    <p class="body-text-m">{{respMensaje}}</p>
                </div>
            </div>
        </div>
    </div>
</div>